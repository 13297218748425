/**
 * Created by pstavros on 6/8/2015.
 */
$(function () {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="token"]').attr('value')
        }
    });
    $('#about').on('ajax:error', function (xhr, status, error) {
        window.alert(error.toString)
    });

    $('.api').on('ajax:success', function (event, data, status, xhr) {
        //window.alert(data);
        $('#' + data).hide();
        //location.reload();
    });

    $(".api").on('ajax:beforeSend', function (event, xhr, settings) {
        xhr.setRequestHeader('X-CSRF-TOKEN', $('meta[name="token"]').attr('value'));
    });

    $(".voucherDetails").on('ajax:beforeSend', function (event, xhr, settings) {
        //window.alert('here');
        $("[id^='t-row']").css('color', 'blue');
        $(this).parent().parent().css('color', 'red');
    });

    $('.voucherDetails').on('ajax:success', function (event, data, status, xhr) {
        //window.alert(data);
        $('#details').html(data);
    });
});